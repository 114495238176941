import React from 'react'
import loadable from '@loadable/component'

const HomepageContent = loadable(() => import('./Homepage'))
const DefaultContent = loadable(() => import('./Default'))

const index = ({ reducedSize, title, subtitle, hideSubtitle, text }) =>
  reducedSize ? (
    <HomepageContent
      title={title}
      subtitle={subtitle}
      hideSubtitle={hideSubtitle}
      text={text}
    />
  ) : (
    <DefaultContent
      title={title}
      subtitle={subtitle}
      hideSubtitle={hideSubtitle}
      text={text}
    />
  )

export default index
